import { render, staticRenderFns } from "./Utilizatori_list.vue?vue&type=template&id=40604ed4&scoped=true&"
import script from "./Utilizatori_list.vue?vue&type=script&lang=js&"
export * from "./Utilizatori_list.vue?vue&type=script&lang=js&"
import style0 from "./Utilizatori_list.vue?vue&type=style&index=0&id=40604ed4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40604ed4",
  null
  
)

export default component.exports