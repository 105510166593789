<template>
    <el-dialog :title="'Modifica semnatura - ' + selectedObject.Nume" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" label-width="100%" :model='selectedObject' ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                       
                <el-col >
                    <el-form-item label='Semnatura' >
                        <VueSignaturePad width="100%" height="200px" class="border-signature" ref="signaturePad" />
                    </el-form-item>                    
                </el-col>     

            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="warning" plain @click="undo" >Corecteaza semnatura</el-button>            
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import VueSignaturePad from "vue-signature-pad";

    export default {
        name: "UploadSemnatura",
        extends: BasePage,
        components:{
            VueSignaturePad
        },
        data () {
            return {
                showPopup: false,
                selectedObject: { Nume: '', Semnatura: null },
                loadingVisible: true
            }
        },
        methods: {     
            post: async function(url, args={}){
                this.loadingVisible      = true;
                var             response = await this.$http.post(url, args );
                this.loadingVisible      = false;
                if( settings.verify_response(response) )
                {
                    return response.body;
                }
                else
                {
                    this.$router.push("/");
                }
            },       
            async get_info(id){
                this.showPopup      = true;
                this.loadingVisible = true
                var response        = await this.post("utilizatori/get_semnatura", { id: id } );
                this.selectedObject = response.Utilizator;                    
                this.$refs.signaturePad.fromDataURL(this.selectedObject.Semnatura)
                this.loadingVisible = false
            },
            save: async function() {
                const { isEmpty, data }         = this.$refs.signaturePad.saveSignature()                
                this.selectedObject.Semnatura   = data
                
                await this.post("utilizatori/save_semnatura", { object: this.selectedObject } );
                this.showPopup = false;
                this.$refs.signaturePad.undoSignature();
            },
            undo() {
                this.$refs.signaturePad.undoSignature()
            }
        },
        mounted: function() {
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    .border-signature {
      border:2px solid #CECECE;
    }

</style>